<template>
  <v-container pa-0 class="text-left">
    <div class="text-center pb-2">
      <span :class="`display-1 font-weight-bold`" style="border-bottom: solid .5px">
        Exercises
      </span>
    </div>
    <setup-exercise @start-exercise="startExercise">
      <template #other-setup>
        <v-row align="center">
          <v-col class="">
            Exercise
          </v-col>
          <v-col>
            <select-exercise exercise-var="exercise"
                             :current-exercise="exercise"
                             @updated="setExercise">
              <v-text-field hide-details dense readonly
                            :value="exercise.name"
                            append-outer-icon="$vuetify.icons.edit"
                            color="accent"/>
            </select-exercise>
          </v-col>
        </v-row>
      </template>
    </setup-exercise>
  </v-container>
</template>

<script>
import { mapActions, mapGetters }              from 'vuex'
import singingExercises                        from 'framework/resources/json/singing-exercises.json'

export default {
  name:       'Exercises',
  components: {
    SelectExercise: () => import('sections/SelectExercise'),
    SetupExercise:  () => import('src/sections/SetupExercise')
  },

  destroyed() {
    if(this.getIsTransportStarted) {
      this.stopPlayback()
    }
  },

  data() {
    return {
      exercise:      singingExercises[0],
    }
  },

  computed: {
    ...mapGetters('transport', ['getIsTransportStarted', 'getTransportBPM']),
    ...mapGetters('context', ['getPitch']),

    timer: {
      get() {
        return this.timerMS / 1000
      },
      set(val) {
        this.timerMS = val * 1000
      }
    }
  },

  methods: {
    ...mapActions('transport', [
      'setBPM', 'stopPlayback'
    ]),

    startExercise(query) {
      const { name, preHarmony, intervals } = this.exercise
      const exerciseQuery = `name=${ name }&preHarmony=${ preHarmony }&intervals=${ intervals }`
      this.$router.push(`/exercise?${query}&${exerciseQuery}`)
    },

    setExerciseProperty({ prop, val }) {
      this[prop] = val
    },

    setExercise(exercise) {
      this.exercise = exercise
    }
  }
}
</script>
